import React from 'react'

//import pdf from '../assets/files/cp.202010.pdf'
//import pdf from '../assets/files/cp.202011.pdf'
//import pdf from '../assets/files/cp.202012.pdf'

const Top = () => (
<div id="top">
</div>
)

export default Top


/*
<a href={pdf} target="_blank" rel="noreferer">【みんなお得！初来店の方 / ご同伴の方】<span>Welcom to the ROCKLANDSプログラム！</span>詳細はこちら＞</a>
*/
